@import "./color.scss";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100%;
  padding: 0;
}

.paddingTop40 {
  padding-top: 40px;
}

.red {
  color: red;
}

.f-ligth {
  font-weight: 300 !important;
}

.f-regular {
  font-weight: 400 !important;
}

.f-minor-bold {
  font-weight: 500 !important;
}

.f-semi-bold {
  font-weight: 600 !important;
}

.f-bold {
  font-weight: 700 !important;
}

.f-8 {
  font-size: 8px !important;
}

.f-9 {
  font-size: 9px !important;
}

.f-10 {
  font-size: 10px !important;
}

.f-11 {
  font-size: 11px !important;
}

.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-15 {
  font-size: 15px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-36 {
  font-size: 36px !important;
}

.f-44 {
  font-size: 44px !important;
}

.f-48 {
  font-size: 48px !important;
}

.f-52 {
  font-size: 52px !important;
}

.normal-shadow {
  box-shadow: 0px 8px 12px rgba(73, 85, 102, 0.122);
}

.minor-shadow {
  box-shadow: 0px 4px 12px rgba(73, 85, 102, 0.122);
}

.f-montserrat {
  font-family: "Montserrat";
}

.f-helvetica {
  font-family: "Helvetica";
}

.f-oxygen {
  font-family: "Oxygen";
}

.f-source-code {
  font-family: "Source Code Pro";
}

.btn-disabled {
  background: #8080804d !important;
  cursor: not-allowed !important;
}

.fade-in {
  animation: fadeIn ease 0.5s;
  -webkit-animation: fadeIn ease 0.5s;
  -moz-animation: fadeIn ease 0.5s;
  -o-animation: fadeIn ease 0.5s;
  -ms-animation: fadeIn ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbarColor transparent;
}

*::-webkit-scrollbar {
  width: 8px;
  position: relative;
  z-index: 999;
  padding-top: 20px;
}

*::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: transparent;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbarColor;
  border-radius: 20px;
  border: 2px solid transparent;
}
*:focus {
  outline: none !important;
  box-shadow: none !important;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
