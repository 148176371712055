@import "./color.scss";

.ts-header-bar {
  height: 65px;
}

.container-fluid {
  padding-bottom: 0;
}

.site-title {
  position: relative;
  top: -8px;
  height: 35px;
  min-width: 85px;
  background-image: url("../src/assets/logo@4x.png");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  pointer-events: none;
}

.site-title > a:hover {
  text-decoration: none;
}
.layout-content {
  margin-top: 15px;
}

.nav-bar {
  .navlink {
    padding: 0 8px;
    &:hover {
      a {
        transform: scale(1.2);
      }
    }
    a {
      opacity: 0.72;
      line-height: 1;
      color: #3b4149;
      transition: all 0.3s linear;
      cursor: pointer;
      &.active {
        opacity: 1;
        font-weight: 700;
      }
    }
    &.home {
      font-size: 24px;
      a {
        display: flex;
      }
    }
  }
  .ant-menu-horizontal > .ant-menu-item,
  .ant-menu-horizontal > .ant-menu-submenu {
    border-bottom: none !important;
  }
  .ant-menu {
    border-bottom: 0px;
    color: #3b4149;
    opacity: 0.72;
    .ant-menu-item {
      transition: all 0.3s ease-in;
      a {
        color: #3b4149;
      }
      &:hover {
        color: #3b4149;

        border: none;
      }
    }

    .ant-menu-item-selected,
    .ant-menu-item-active {
      color: #3b4149;
      transition: all 0.3s ease-in;
      border: none;
      a {
        color: #3b4149;
      }
      &:hover {
        transform: scale(1.2);
        color: #3b4149;
        opacity: 1;
        border: none;
      }
    }
    .ant-menu-item-selected {
      font-weight: 700;
      a {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .ant-menu-item-active {
      &.ant-menu-item-selected {
        a {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }

    .ant-menu-submenu {
      border-bottom: none;
      color: #3b4149;
      &:hover {
        color: #3b4149;
        opacity: 1;
        border-bottom: none;
      }
      .ant-menu-submenu-title {
        color: #3b4149;
      }
    }
  }
}

.ant-menu-submenu {
  .ant-menu-item {
    color: #3b4149;
    &:hover {
      background-color: rgba(59, 65, 73, 0.07);
    }
    &.ant-menu-item-selected {
      background-color: rgba(59, 65, 73, 0.07);
      font-weight: 700;
      a {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    a {
      transition: all 0.3s ease-in;
      &:hover {
        transition: all 0.3s ease-in;
        color: #3b4149;
      }
    }
  }
  .ant-menu-submenu-title {
    color: #3b4149;
    &:hover {
      background-color: rgba(59, 65, 73, 0.07);
      > .ant-menu-submenu-arrow {
        color: #3b4149;
      }
    }
  }
}

